<template>
    <BT-Blade-Items
        addBladeName="customer-order"
        bladeName="customer-orders"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :dependantBlades="[{ bladeName: 'customer-order' }]"
        :getNewBladeData="getNewBladeData"
        :getSelectBladeData="getSelectBladeData"
        :getParams="item => { return { includeDetails: false, customerIDs: item.data == null ? null : item.data.customerID } }"
        :headers="[
            { text: '', value: 'creator', display: true, width: 15 },
            { text: 'CO#', value: 'customerOrderNumber', subtitle: 2, prefix: 'CO#', searchable: true },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: 1, prefix: 'Due: ' },
            { text: 'Customer', value: 'buyer.companyName', title: 1, searchable: true },
            { text: 'Fulfiller', value: 'fulfiller', title: 2, searchable: true, display: true },
            { text: 'Destination', value: 'location', textFilter: 'toLocationLine', subtitle: 0, hide: true },
            { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency' }]"
        :hoverItemActions="false"
        :loadingMsg="loadingMsg"
        navigation="customer-orders"
        :onPullSuccessAsync="pullOrders"
        selectKey="isSelectedToProcess"
        :searchProps="['customerOrderNumber']"
        :selectMany="selectMany"
        title="Customer Orders"
        useServerPagination>
        <template #creator="{ item }">
            <v-icon v-if="item.createdByEntity == 'Customer'" small title="Created by customer">mdi-account</v-icon>
            <v-icon v-else-if="item.createdByEntity == 'Supplier'" small title="Created by supplier">mdi-factory</v-icon>
            <v-icon v-else-if="item.createdByEntity == 'Standing Order'" small title="Created by standing order automation">mdi-robot</v-icon>
        </template>
        <template v-slot:settings="{ items }">
            <v-list-item :to="{ name: 'customer-orders-import' }">
                <v-list-item-icon>
                    <v-icon small>mdi-import</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Import CSV</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'customer-orders-list' }">
                <v-list-item-icon>
                    <v-icon small>mdi-clipboard-list</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Bulk Lists</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!selectMany" @click="selectUnprocessedOrders(items)">
                <v-list-item-icon>
                    <v-icon small>mdi-checkbox-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Select Unprocessed Orders</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="selectMany = false">
                <v-list-item-icon>
                    <v-icon small>mdi-checkbox-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Hide Selection</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'ordering-user-activities' }">
                <v-list-item-icon>
                    <v-icon small>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>User Activity</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <BT-Entity
                v-if="$canView('supplier-settings')"
                ignoreID
                inline
                navigation="supplier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-divider />
                    <v-subheader>Actions <v-spacer />
                        <v-btn small icon :to="{ name: 'ordering-actions' }" title="View Past Actions">
                            <v-icon small>mdi-history</v-icon>
                        </v-btn>
                    </v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Generate Standing Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Automation v-model="item.standingOrderAutomation" @change="save" />
                                <v-btn icon small @click="generateStandingOrders" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Process Customer Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn small icon :to="{ name: 'customer-order-schedules' }" title="Manage Schedules">
                                    <v-icon small>mdi-timer-cog</v-icon>
                                </v-btn>
                                <BT-Menu-Automation v-model="item.processAutomation" @change="save" />
                                <v-btn icon small @click="processCustomerOrders" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Process All Unprocessed Customer Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn icon small @click="processAllCustomerOrders" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Invoice Customer Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Automation v-model="item.invoiceAutomation" @change="save" />
                                <v-btn icon small @click="invoiceCustomerOrders" title="Now">
                                    <v-icon small class="mx-1">mdi-receipt</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                    <v-subheader>Settings</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Last Customer Order Number</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <span>#{{ item.lastCustomerOrderNumber }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model.number="item.lastCustomerOrderNumber"
                                        @change="save"
                                        prepend-icon="#"
                                        singleLine
                                        type="number"
                                        hideDetails />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Invoice Numbering</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <BT-Toggle-Trigger-Icon
                                v-model="item.invoiceNumberTrigger"
                                defaultValue="Manual"
                                :toggleOptions="[
                                    { text: 'Manual', value: 'Manual', icon: 'mdi-boom-gate' },
                                    { text: 'Auto', value: 'Auto', icon: 'mdi-boom-gate-up' },
                                    { text: 'Use Customer Order Numbers', value: 'OrderNumber', icon: 'mdi-content-copy' }]"
                                @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="item.invoiceNumberTrigger == 'Auto'">
                        <v-list-item-content>
                            <v-list-item-subtitle>Invoice Number Prefix</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <span :class="item.invoicePrefix == null ? 'grey--text lighten-2' : ''">{{ item.invoicePrefix || '(prefix)' }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="item.invoicePrefix"
                                        @change="save"
                                        label="Prefix"
                                        placeholder="Prefix"
                                        prepend-icon="#"
                                        singleLine
                                        hideDetails />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-if="item.invoiceNumberTrigger == 'Auto'">
                        <v-list-item-content>
                            <v-list-item-subtitle>Last Invoice Number</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog v-if="item.invoiceNumberTrigger == 'Auto'">
                                <span>#{{ item.invoicePrefix }}{{ item.lastInvoiceNumber }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model.number="item.lastInvoiceNumber"
                                        @change="save"
                                        label="Last Invoice Number"
                                        :prepend-icon="'#' + (item.invoicePrefix || '')"
                                        singleLine
                                        type="number"
                                        hideDetails />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Email Order Notifications To</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <v-btn small icon :title="item.emailAddressesAsSupplier">
                                    <v-icon small :class="item.emailAddressesAsSupplier == null || item.emailAddressesAsSupplier.length == 0 ? '' : 'success--text'">mdi-email</v-icon>
                                </v-btn>
                                <template v-slot:input>
                                    <BT-Tags 
                                        v-model="item.emailAddressesAsSupplier"
                                        @change="save" />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Email New Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                             <BT-Toggle-Trigger-Icon
                                v-model="item.emailNewOrders"
                                :defaultValue="false"
                                :successValue="true"
                                :toggleOptions="[
                                    { text: 'Off', value: false, icon: 'mdi-boom-gate' },
                                    { text: 'On', value: true, icon: 'mdi-boom-gate-up' }]"
                                @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Email Confirmed Orders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                             <BT-Toggle-Trigger-Icon
                                v-model="item.emailConfirmedOrders"
                                :defaultValue="false"
                                :successValue="true"
                                :toggleOptions="[
                                    { text: 'Off', value: false, icon: 'mdi-boom-gate' },
                                    { text: 'On', value: true, icon: 'mdi-boom-gate-up' }]"
                                @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Fulfiller Selecting</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn small icon :to="{ name: 'fulfiller-pointers' }" title="Manage Automatic Fulfillment Selecting">
                        <v-icon small>mdi-routes</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <BT-Entity
                v-if="$canView('courier-settings')"
                ignoreID
                inline
                navigation="courier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Courier Selecting</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn small icon :to="{ name: 'courier-pointers' }" title="Manage Automatic Courier Selecting">
                                    <v-icon small>mdi-routes</v-icon>
                                </v-btn>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.courierSelectionGuideAsSupplier)"
                                    v-model="item.courierSelectionTriggerAsSupplier"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon 
                                    v-model="item.courierSelectionGuideAsSupplier"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
        </template>
        <template v-slot:fulfiller="{ item }">
            <BT-Btn
                v-if="item.isConfirmed == true && item.fulfillerID == null"
                @click="selectFulfiller(item, true)"
                icon="mdi-account"
                inline
                label="Self"
                small
                title="My company will fulfill this order" />
            <span v-if="item.fulfiller != null">
                <v-btn v-if="!item.isDispatched" icon @click.stop="unselectFulfiller(item)" small>
                    <v-icon class="error--text" small>mdi-close</v-icon>
                </v-btn>
                {{ item.fulfiller.companyName }}
            </span>
            <BT-Btn
                v-else-if="item.isConfirmed == true && item.fulfillerID == null"
                @click="selectFulfiller(item, false)"
                icon="mdi-arrow-decision"
                inline
                label="Fulfill Via"
                small
                title="Select a company to fulfill this order" />
            <span v-else></span>
        </template>
        <template v-slot:itemActions="{ item, items }">
            <span 
                v-if="item.isConfirmed === false" 
                class="error--text my-auto">*Rejected</span>

            <BT-Single-Action
                v-if="item.isConfirmed == null"
                :loading="item.loadingCount > 0"
                @click="approvePurchaseOrder(item, true)"
                small
                title="Approve" />

            <BT-Single-Action
                v-if="item.isConfirmed && item.fulfillerID != null && item.fulfillerID == item.sellerID && !item.isDispatched"
                :loading="item.loadingCount > 0"
                small
                title="Proceed to next step of customer order - a stock consignment"
                @click="processPurchaseOrder(item)" />

            <v-btn
                v-if="item.isConfirmed && !item.isDispatched && !item.isFulfilled"
                @click.stop="invoicePurchaseOrder(item, items)"
                icon
                :loading="item.loadingCount > 0"
                small
                title="Invoice Customer Order">
                <v-icon small>mdi-receipt</v-icon>
            </v-btn>

        </template>

        <template v-slot:selectHeader="{ items }">
            <v-btn small icon @click.stop="processUnprocessedCustomerOrders(items)">
                <v-icon small>mdi-arrow-right-circle</v-icon>
            </v-btn>
        </template>

        <template v-slot:selectItem="{ item }">
            <v-btn v-if="!item.isDispatched" small icon @click.stop="item.isSelectedToProcess = !item.isSelectedToProcess">
                <v-icon v-if="item.isSelectedToProcess" small class="success--text">mdi-check</v-icon>
                <v-icon v-else small class="error--text">mdi-close</v-icon>
            </v-btn>
        </template>

        <template slot="actions">
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Customer-Orders-Blade',
    components: {
        BTSingleAction: () => import('~components/BT-Single-Action.vue'),
        // BTMenu: () => import('~components/BT-Menu.vue'),
        BTMenuAutomation: () => import('~components/BT-Menu-Automation.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    data: function() {
        return {
            bladeData: null,
            loadingMsg: null,
            msg: null,
            selectMany: false
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        async unselectFulfiller(po) {
            this.loadingMsg = 'Cancelling';
            
            try {
                var patchData = {
                    id: po.id,
                    rowVersion: po.rowVersion
                }

                var res = await this.$BlitzIt.api.patch('customer-order-fulfilling', patchData);
                
                po.fulfillerID = null;
                po.fulfiller = null;
                po.rowVersion = res.data.data.rowVersion;
            }
            // catch (err) {
            //     po.errorMsg = this.extractErrorDescription(err);
            // }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectFulfiller(po, isSelf = false) {
            this.loadingMsg = 'Finding a company to fulfill this order';
            
            try {
                var patchData = {
                    id: po.id,
                    isSelf: isSelf,
                    rowVersion: po.rowVersion
                }

                var sup = null;
                if (!isSelf) {
                    sup = await this.$selectItem({
                        navigation: 'couriers-fulfilling',
                        itemText: 'seller.companyName'
                    });

                    if (sup == null) {
                        return;
                    }

                    patchData.fulfillerAgreementID = sup.id;
                }
                
                var res = await this.$BlitzIt.api.patch('customer-order-fulfilling', patchData);
                
                if (isSelf) {
                    po.fulfillerID = po.sellerID;
                    po.fulfiller = po.seller;
                }
                else {
                    po.fulfillerID = sup.sellerID;
                    po.fulfiller = sup.seller;
                }
                
                po.rowVersion = res.data.data.rowVersion;
            }
            catch (err) {
                po.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async approvePurchaseOrder(po, approval) {
            this.loadingMsg = 'Approving';
            
            try {
                var res = await this.$BlitzIt.api.patch('customer-order-approval', { id: po.id, isConfirmed: approval, rowVersion: po.rowVersion });
                po.isConfirmed = approval;
                po.rowVersion = res.data.data.rowVersion;
            }
            catch (err) {
                po.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async invoicePurchaseOrder(po, items) {
            var proceed = await this.$confirm({ text: 'This will turn the order into an invoice. Are you sure?', acceptText: 'Yes', cancelText: 'No' });
            if (proceed === true) {
                this.loadingMsg = 'Invoicing';

                var data = { id: po.id, performedOn: null, useOriginalOrderItems: true, rowVersion: po.rowVersion, orderItems: [] }
                try {
                    await this.$BlitzIt.api.patch('customer-order-invoicing', data);
                    this.$BlitzIt.store.deleteLocal('customer-orders', po.id, null);
                    var ind = items.findIndex(x => x.id == po.id);
                    if (ind >= 0) {
                        items.splice(ind, 1);
                    }
                }
                catch (err) {
                    po.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        },
        async generateStandingOrders() {
            this.loadingMsg = 'Generating';

            try {
                await this.$BlitzIt.store.post('ordering-actions', { functionName: 'GenerateStandingOrders' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        getNewBladeData(bladeData) {
            console.log('n');
            console.log(bladeData);
            return {
                agreementID: bladeData.data.agreementID
            }
        },
        getSelectBladeData(bladeData, item) {
            return {
                agreementID: item.supplyAgreementID
            }
        },
        async approveCustomerOrders() {
            this.loadingMsg = 'Approving Orders';

            try {
                await this.$BlitzIt.store.post('ordering-actions', { functionName: 'ApproveCustomerOrders' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async invoiceCustomerOrders() {
            this.loadingMsg = 'Invoicing';

            try {
                await this.$BlitzIt.store.post('ordering-actions', { functionName: 'InvoiceCustomerOrders' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async processCustomerOrders() {
            this.loadingMsg = 'Processing';

            try {
                await this.$BlitzIt.store.post('ordering-actions', { functionName: 'ProcessCustomerOrders' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async processAllCustomerOrders() {
            this.loadingMsg = 'Processing';

            try {
                await this.$BlitzIt.store.post('ordering-actions', { functionName: 'ProcessAllCustomerOrders' });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async processPurchaseOrder(po) {
            try {
                this.loadingMsg = 'Processing';
                var res = await this.$BlitzIt.api.patch('fulfiller-orders', {
                    id: po.id,
                    isProcessed: true,
                    rowVersion: po.rowVersion
                });
                po.isDispatched = true;
                po.rowVersion = res.data.data.rowVersion;
            }
            catch (err) {
                po.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async processUnprocessedCustomerOrders(list) {
            try {
                var fList = list.filter(z => !z.isDispatched && z.isSelectedToProcess);
                for (let i = 0; i < fList.length; i++) {
                    const order = fList[i];
                    this.loadingMsg = `Processing ${i + 1} of ${fList.length} customer orders`;
                    await this.processPurchaseOrder(order);
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pullOrders(orders) {
            return orders.map(x => Object.assign({}, x, { isSelectedToProcess: false }))
        },
        selectUnprocessedOrders(orders) {
            orders.forEach(o => {
                if (!o.isDispatched) {
                    o.isSelectedToProcess = true;
                }
                else {
                    o.isSelectedToProcess = false;
                }
            })
            
            this.selectMany = true;
        }
    }
}
</script>